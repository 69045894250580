import React, {useState, useEffect, useRef} from "react"
import styled from 'styled-components';
import AOS from 'aos';
import getFirebase from 'src/utilities/firebase'
import withSize from 'src/utilities/withSize'
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import ThemeCarousel from 'src/components/ThemeCarousel'
import SupportHelmet from 'src/components/SupportHelmet'
import LoginModal from 'src/components/LoginModal'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { identify, trackPage, trackEvent, getPropertiesFromLocation, } from 'src/utilities/analytics'
import { createBetaUserSubscription } from 'src/utilities/http-requests'
import { createResume } from 'src/utilities/firestore'
import { Link, navigate } from "gatsby"
import EditorPreviewerImage from 'src/images/resume-builder-editor-previewer-wider-no-resume-name-screenshot.jpg'
import ArrowRight from 'src/images/icons/arrow-right.svg'
import "aos/dist/aos.css";

// deprecated

// this is a copy of home
// tom wanted this. not sure if necessary since i also capture referring page and referring cta

const pageName = 'Home-Variant-1'
const isBeta = false

const Welcome = ({currentUser}) => {
  const arrowRightStyle = {
    width: 17,
    height: 17,
  }
  // MARK: - BETA FLAG
  const templatesPath = '/resume-templates'
  const ctaDestinationURL = currentUser ? '/resumes' : templatesPath

  return (
    <section className="pt-4 pt-md-11 position-relative">
    <div class="shape shape-fluid-x shape-blur-1 text-gray-200">
      <svg viewBox="0 0 723 569" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M703.969 241.602l-.006-.003C716.081 262.97 723 287.677 723 314c0 68.917-47.425 126.757-111.42 142.665L246.7 556.937C226.465 564.729 204.481 569 181.5 569 81.26 569 0 487.74 0 387.5c0-34.256 9.49-66.296 25.985-93.633l-.016.008L141.512 77.548C162.753 33.305 207.123 2.273 258.951.12l.008-.12h251.04l.003.01c41.848.557 78.081 24.378 96.356 59.12l.001-.005 97.61 182.477z" fill="currentColor"></path></svg>
    </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            <div class="img-skewed img-skewed-left mb-8 mb-md-0">
              <img src={EditorPreviewerImage} alt="..." class="screenshot img-fluid mw-md-130" data-aos="img-skewed-item-left" data-aos-delay="300" />
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-6 order-md-1" data-aos="fade-up">
            <h1 className="display-3 text-center text-md-left">
              Beautiful resume<br/>
              in 5 minutes<br/>
            </h1>
            <p className="lead text-center text-md-left text-muted mb-6 mb-lg-8">
              Build a beautiful, modern resume in 5 minutes. <br/>
              Fill out a form, choose from one of our professionally-designed templates, and download as a PDF.<br/>
              Try now for free!
            </p>
            <div className="text-center text-md-left">
              <Link to={ctaDestinationURL} className="btn btn-primary shadow lift mr-1">
                Build your resume <ArrowRight style={arrowRightStyle} fill='white' className="fe fe-arrow-right d-none d-md-inline ml-3"/>
              </Link>
            </div>

          </div>
        </div>
      </div>
    </section>
    )
}

const Feature = ({title, body, image}) => {
  return (
    <div className="col-12 col-md-4" data-aos="fade-up">
    <div className="icon text-primary mb-3">
      {/* image */}
    </div>
    <h3>
      {title}
    </h3>
    <p className="text-muted mb-6 mb-md-0">
      {body}
    </p>
  </div>
  )
}

const Features = () => {
  return (
    <section className="py-8 py-md-11">
      <div className="container">
        <div className="row">
          <Feature
            title='Win Interviews'
            body='98% of job seekers are eliminated in resume screening. Stand out from the crowd with a professionally-designed resume.'
          />
          <Feature
            title='Easy and fast'
            body='Our software builds your resume from one of our professionally-designed templates. Just fill in your details.'
          />
          <Feature
            title='Try now for free'
            body='You can start building your resume and preview our templates for free right now.'
          />
        </div>
      </div>
    </section>
    )
}

const textColor = '#f2f5fa'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #21277f;
    padding-top: 50px;
    padding-bottom: 20px;
  `
const TextContainer = styled.div`
    color: ${textColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    font-weight: bold;
  `

const CarouselContainer = ({size, onClickSlide, className}) => {
  const {
    width,
  } = size

  const carouselStyle = {
    width: (width || 1600) - 60,
    backgroundColor: '#21277f',
  }
  // want image container to be about a third of width with margin
  // then make height proportional
  // max 450
  const imageContainerWidth = Math.min((width - 60) / 3, 450)
  const imageContainerHeight = imageContainerWidth / .70735

  const imageContainerStyle = {
    width: imageContainerWidth,
    height: imageContainerHeight,
  }

  const imageStyle = {
    width: '90%',
    height: '90%',
  }
  // these overrides make it possible to show 3 slides
  // and hide the partial slides on side
  const carouselSettings = {
    variableWidth: false,
    variableHeight: false,
    slidesToShow: 3,
    dotsClass: 'slick-dots slick-dots-light',
  }

  const headerStyle = {
    marginBottom: -20,
  }

  const slideStyleOverrides = {
    titleStyle: {
      color: textColor,
    }
  }
  return (
    <Container className={className}>
      <TextContainer>
        <h2>
          Beautiful, ready-to-use resume templates
        </h2>
        <h3>
          Professionally-designed resume templates. Recruiter recommended. Download to PDF in 1 click.
        </h3>
      </TextContainer>
      <ThemeCarousel
        heightProportion={40}
        style={carouselStyle}
        {...{onClickSlide, imageContainerStyle, imageStyle, carouselSettings, headerStyle, slideStyleOverrides}}
      />
    </Container>
    )
}

const StyledCarouselContainer = styled(CarouselContainer)`
  @media screen and (max-width: 768px) {
    display: none !important;
  }
`
const CarouselContainerWithSize = withSize(StyledCarouselContainer)

const IndexPage = ({location}) => {

  const firebase = getFirebase();
  const [currentUser, setCurrentUser] = useState(null)
  const selectedThemeRef = useRef()

  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      identify(user)
      attemptCreateResume({currentUser: user})
    });
    }, [firebase]);

  useEffect(() => {
    trackPage(
      pageName,
      getPropertiesFromLocation(location),
    )
  }, [])

  // animate on scroll
  useEffect(() => {
    AOS.init({
      duration : 2000,
      once: true,
    });
    AOS.refresh();
  }, []);

  // create a resume if logged in and resume selected
  // call this function when either necessary condition updates:
  // template selected or user logged in
  // currentUser as state won't necessarily update synchronously
  // so this function takes it directly. see onAuthStateChanged
  const attemptCreateResume = ({currentUser}) => {

    if (!selectedThemeRef.current) {
      return
    }

    if (!currentUser) {
      setLoginModalIsOpen(true)
      return
    }

    let betaUserSubscriptionPromise = Promise.resolve(null)
    if (isBeta && currentUser) {
      betaUserSubscriptionPromise = createBetaUserSubscription({userUID: currentUser.uid})
                                      .then(() => console.log('created beta user subscription'))
    }

    const resumeInfo = {
      metadata: {
        theme: selectedThemeRef.current
      }
    }
    const createResumePromise = createResume({
      ownerUID: currentUser.uid,
      resumeInfo
    })

    Promise.all([betaUserSubscriptionPromise, createResumePromise])
    .then(([empty, docRef]) => {
      navigate(`/resumes/${docRef.id}`)
    })
    .catch(error => console.error("Error creating resume: ", error))
  }

  const onClickSlide = ({id, source}) => {
    selectedThemeRef.current = id
    attemptCreateResume({currentUser})
    trackEvent('clicked_select_template', {
      page: pageName,
      template_id: id,
      source,
    })
  }
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false)
  const onRequestClose = () => {
    setLoginModalIsOpen(false)
  }

  // do nothing. handle login in auth state changed hook
  const signInSuccessWithAuthResult = () => false

  return (
    <Layout
      onSignInSuccess={() => navigate('/resumes')}
    >
      <SEO title='Build a Beautiful Resume in 5 Minutes' />
      <SupportHelmet/>
      <AnalyticsHelmet/>
      <LoginModal
        isOpen={loginModalIsOpen}
        shouldAllowSkipLogin={true}
        {...{ firebase, onRequestClose, signInSuccessWithAuthResult }}
      />
      <Welcome {...{currentUser}}/>
      <Features/>
      <CarouselContainerWithSize
        {...{onClickSlide}}
      />
    </Layout>
  )
}

export default IndexPage
